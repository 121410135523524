








































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import _ from 'lodash'
import PageHeader from '@/admin/components/PageHeader.vue'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Company from '@/shared/modules/company/company.model'
import User from '@/shared/modules/user/user.model';
import { AuthGetters } from '@/shared/store/auth/auth.getters';
import AddPersonForm from "@/company/components/org-structure/AddPersonForm.vue";
import StructureTreeNode from "@/company/components/org-structure/StructureTreeNode.vue";
import CreateGroupDialog from "@/company/components/org-structure/CreateGroupDialog.vue";
import localeHelper from '@/shared/helpers/locale.helper'
import baseHttp from "@/shared/http";
import { getNodeApiUrlByVersion } from "@/config";
import { RoleLevel } from '@/shared/modules/role/role.model'
import RoleCan from "@/shared/components/RoleCan.vue";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";


@Component({
  components: { PageHeader, AddPersonForm, StructureTreeNode, RoleCan },
  methods: { __ }
})
export default class OrgStructureIndex extends Vue {
  RoleLevel = RoleLevel
  http = baseHttp

  addPersonNodeKey: string | boolean = false
  addPersonEditEntry = null
  structure: Record<string, any> | null = null;
  selectedTreeNodeKey: string | null = null;

  mounted() {
    this.http.get(`${getNodeApiUrlByVersion()}/org-structures`)
      .then(resp => this.structure = resp.data.structure)
  }

  setShowAddPersonForm() {
    this.addPersonNodeKey = true
  }
  onCancelAddPerson() {
    this.addPersonNodeKey = false;
  }

  openAddGroupDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateGroupDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        placedUnderOptions: this.placeUnderOptions,
        onSuccess: this.onAddSubmit,
      },
    })
  }

  onAddSubmit(data: any, nodeKey?: string) {
    if (!this.structure || Object.keys(this.structure).length === 0) {
      this.structure = { data: data };
    }
    else {
      const isFirstBlock = nodeKey === '';
      const newStructure = _.cloneDeep(this.structure || {});
      let existingNode = null;
      if (typeof nodeKey === 'string') {
        existingNode = isFirstBlock ? newStructure : _.get(newStructure, nodeKey);
      }
      const placementKey = `${data.placedUnder}${data.placedUnder ? '.' : ''}children`;

      // Edit
      if (existingNode) {
        // Only info edited but not structure
        if (existingNode.data?.placedUnder === data.placedUnder) {
          if (isFirstBlock) _.set(newStructure, 'data', data);
          else _.set(newStructure, nodeKey as string, { ...existingNode, data });
        }
        else if (isFirstBlock) {
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.error,
            message: __('Company should have first level employee')
          })
        }
        else {
          _.unset(newStructure, nodeKey as string);
          const target = _.get(newStructure, placementKey);
          if (!target) _.set(newStructure, placementKey, [{ ...existingNode, data }]);
          else _.set(newStructure, placementKey, [...target, { ...existingNode, data }]);
        }
      }
      else {
        const target = _.get(newStructure, placementKey);
        if (!target) _.set(newStructure, placementKey, [{ data }]);
        else _.set(newStructure, placementKey, [...target, { data }]);
      }

      this.structure = this.normalizeStructure(newStructure);
    }

    this.addPersonEditEntry = null;
    this.addPersonNodeKey = false;
    this.selectedTreeNodeKey = null;

    this.http.post(`${getNodeApiUrlByVersion()}/org-structures`, {
      structure: this.structure,
    });
  }

  // Tree actions
  onNodeSelect(key: string) {
    this.selectedTreeNodeKey = key;
  }
  onNodeEdit(key: string) {
    const target = key ? _.get(this.structure, key) : this.structure;
    const split = key.split('.')
    const placedUnderValue = key ? split.slice(0, split.length - 1).join('.') : '';
    if (target?.data?.isGroup) {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: CreateGroupDialog,
        maxWidth: 570,
        persistent: true,
        meta: {
          item: { ...(target?.data || {}), placedUnder: placedUnderValue },
          nodeKey: key,
          placedUnderOptions: this.placeUnderOptions,
          onSuccess: this.onAddSubmit,
        },
      })
    }
    else {
      this.addPersonEditEntry = {
        ...(target?.data || {}),
        manualPerson: target?.data.name,
        placedUnder: placedUnderValue,
      }
      this.addPersonNodeKey = key;
    }
  }
  onNodeDelete(key: string) {
    if (!key) {
      this.structure = {};
    }
    else {
      const structure = _.cloneDeep(this.structure);
      _.unset(structure, key);

      const filter = (children: any[]) => {
        const result: any[] = [];
        children.forEach((child: any) => {
          if (!child) return;

          let { children, ...childCopy } = child;
          if (children) {
            children = filter(children);
            if (children.length) childCopy.children = children;
          }

          result.push(childCopy)
        });

        return result;
      }
      if (structure?.children) {
        structure.children = filter(structure.children);
        if (!structure.children.length) delete structure.children;
      }

      this.structure = this.normalizeStructure(structure);
    }

    this.selectedTreeNodeKey = null;

    this.http.post(`${getNodeApiUrlByVersion()}/org-structures`, {
      structure: this.structure,
    });
  }
  normalizeStructure(structure: any) {
    const filter = (children: any[]) => {
      const result: any[] = [];
      children.forEach((child: any) => {
        if (!child) return;

        let { children, ...childCopy } = child;
        if (children) {
          children = filter(children);
          if (children.length) childCopy.children = children;
        }

        result.push(childCopy)
      });

      return result;
    }

    if (structure?.children) {
      structure.children = filter(structure.children);
      if (!structure.children.length) delete structure.children;
    }

    return structure;
  }

  generatePdf() {
    this.http.get(`${getNodeApiUrlByVersion()}/org-structures/pdf`, { responseType: 'blob' })
      .then(resp => {
        const url = window.URL.createObjectURL(resp.data);
        window.open(url, '_blank');
      });
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get placeUnderOptions(): any[] {
    const options: { value: string, title: string }[] = []

    const addOption = (obj: any, value = '') => {
      if (!obj || Object.keys(obj).length === 0) return;
      options.push({
        value: value,
        title: obj.data.isGroup ?
          obj.data.title[localeHelper.getLocale()] :
          [obj.data.position[localeHelper.getLocale()], obj.data.company, obj.data.name].filter(a => !!a).join('/')
      });
      if (obj.children) obj.children.forEach((child: any, index: number) => {
        addOption(child, `${value}${value ? '.' : ''}children[${index}]`)
      });
    }
    addOption(this.structure)

    return options;
  }

  get structureNotEmpty(): boolean {
    return !!this.structure && Object.keys(this.structure).length > 0;
  }
}
