















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import __ from "@/shared/helpers/__";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import User from '@/shared/modules/user/user.model'
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import FormField from "@/shared/components/form/FormField.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import CreateCompetenceDialog from "@/company/components/org-structure/CreateCompetenceDialog.vue";
import localeHelper from "@/shared/helpers/locale.helper";
import CreatePositionDialog from "@/company/components/org-structure/CreatePositionDialog.vue";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import baseHttp from "@/shared/http";
import { getNodeApiUrlByVersion } from '@/config';


@Component({
  methods: { __ },
  components: { FormField }
})
export default class AddPersonForm extends Vue {
  @Prop() item!: User
  @Prop() onSave!: (item: any, nodeKey?: string) => void
  @Prop() onCancel!: (item: any) => void
  @Prop() placeUnderOptions!: { value: string, title: string }[]
  @Prop() nodeKey!: string | boolean
  tooltipShow: boolean = false

  http = baseHttp

  formData: Record<string, any> = {
    ...(this.item || {
      isManual: false,
      person: null,
      manualPerson: '',
      position: {},
      competence: {},
      company: '',
      contactDetails: '',
      placedUnder: ''
    }),
  }

  manualInputField = new Field()
    .setType(FieldTypes.checkbox)
    .setKey('isManual')
    .setHideDetails(true)
    .setDense(true)
    .setValue(this.formData.isManual)
    .setOnChange((value) => {
      this.formData.isManual = value;
      this.personSelect.setValue('');
      this.formData.person = null;
      this.formData.position = {};
      this.personField.setValue('');
      this.formData.manualPerson = '';
    })
  personSelect = new SearchableField()
    .setEntryKey('personUuid')
    .setTitle(__('company.components.org-structure.add-person.person'))
    .loadItems({
      endpoint: `company/${ this.company.slug }/employees`,
      value: 'uuid',
      title: 'full_name',
      perPage: 20,
    })
    .setHideDetails(true)
    .setDense(true)
    .isRequired()
    .setValue(this.formData.personUuid)
    .setOnItemSelect((item: any) => {
      this.formData.person = item?.meta || null
      if (item && item.meta?.position) this.formData.position = {
        [localeHelper.getLocale()]: item.meta?.position?.name,
        uuid: item.meta?.position?.uuid
      }
      else {
        this.formData.position = {}
      }
    })
  personField = new Field()
    .setKey('manualPerson')
    .setTitle(__('company.components.org-structure.add-person.person-select'))
    .setHideDetails(true)
    .setDense(true)
    .isRequired()
    .setValue(this.formData.manualPerson)
    .setOnChange((value) => this.formData.manualPerson = value)
  companyField = new Field()
    .setKey('company')
    .setTitle(__('company.components.org-structure.add-person.company'))
    .setHideDetails(true)
    .setDense(true)
    .setValue(this.formData.company)
    .setOnChange((value) => this.formData.company = value)
  contactField = new Field()
    .setKey('contactDetails')
    .setTitle(__('company.components.org-structure.add-person.contact-details'))
    .setHideDetails(true)
    .setDense(true)
    .setValue(this.formData.contactDetails)
    .setOnChange((value) => this.formData.contactDetails = value)
  placedUnderField = new SelectField()
    .setKey("placedUnder")
    .setTitle(__('company.components.org-structure.add-person.placed-under'))
    .setItems(this.placeUnderOptions.map(option => new SelectItem()
      .setValue(option.value)
      .setTitle(option.title)
    ))
    .setHideDetails(true)
    .setDense(true)
    .setValue(this.formData.placedUnder)
    .setOnChange((value) => this.formData.placedUnder = value)

  created() {
    this.$watch('placeUnderOptions', () => {
      this.placedUnderField.setItems(this.placeUnderOptions.map(option => new SelectItem()
        .setValue(option.value)
        .setTitle(option.title)
      ))
    })
  }

  openCreatePositionDialog(): void {
    if (!this.formData.isManual) return;
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreatePositionDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item: Object.keys(this.formData.position).length ? this.formData.position : undefined,
        onSuccess: (data: any) => {
          this.formData.position = data.name;
          this.$store.dispatch(GlobalActions.closeDialog)
        },
      },
    })
  }

  openCreateCompetenceDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateCompetenceDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item: Object.keys(this.formData.competence).length ? this.formData.competence : undefined,
        onSuccess: (data: any) => {
          this.formData.competence = data.name;
          this.$store.dispatch(GlobalActions.closeDialog)
        },
      },
    })
  }

  async onSaveClick() {
    if (
      (this.formData.isManual && !this.formData.manualPerson) ||
      (!this.formData.isManual && !this.formData.person)
    ) {
      return this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.error,
        message: 'Person is required',
      });
    }

    const data = {
      isManual: this.formData.isManual,
      personUuid: this.formData.person?.uuid || '',
      name: this.formData.isManual ? this.formData.manualPerson : this.formData.person.full_name,
      position: this.formData.position,
      competence: this.formData.competence,
      company: this.formData.company,
      contactDetails: this.formData.contactDetails,
      placedUnder: this.formData.placedUnder,
    }
    if (this.formData.position?.uuid) {
      const position = await this.http.get(`${getNodeApiUrlByVersion()}/positions/${this.formData.position?.uuid}?hasTranslations=true`)
        .then(resp => resp.data)
      data.position = position.name;
    }

    this.onSave(data, typeof this.nodeKey === 'string' ? this.nodeKey : undefined);
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }

  get positionTranslation() {
    return this.formData.position[localeHelper.getLocale()] || '';
  }

  get competenceTranslation() {
    return this.formData.competence[localeHelper.getLocale()] || '';
  }
}
