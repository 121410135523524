















































import { Component, Prop, Vue } from 'vue-property-decorator'
import localeHelper from '@/shared/helpers/locale.helper'
import { GlobalActions } from "@/shared/store/global/global.actions";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import __ from "@/shared/helpers/__";
import { RoleLevel } from '@/shared/modules/role/role.model';
import RoleCan from "@/shared/components/RoleCan.vue";


@Component({
  name: 'StructureTreeNode',
  components: { StructureTreeNode, RoleCan }
})
export default class StructureTreeNode extends Vue {
  @Prop() node!: any
  @Prop() nodeKey!: string
  @Prop() onDelete!: (key: string) => void
  @Prop() onEdit!: (key: string) => void
  @Prop() onSelect!: (key: string) => void
  @Prop() selectedKey!: string

  RoleLevel = RoleLevel

  onSelectClick() {
    this.onSelect(this.nodeKey)
  }

  onDeleteClick() {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: "Delete object",
        text: "Are you sure you want to delete this object? (All object placed under this element will also be deleted.)",
        yes: __('general.ok'),
        no: __('general.cancel'),
        onYes: () => this.onDelete(this.nodeKey),
      },
    })
  }

  onEditClick() {
    this.onEdit(this.nodeKey)
  }

  get currentLang() {
    return localeHelper.getLocale()
  }

  get groupNames() {
    return this.node.data.persons.map((child: any) => {
      const data = [child.name];
      if (child.competence[this.currentLang]) data.push(child.competence[this.currentLang]);
      return data.join(', ');
    })
  }

  get isSelected() {
    return this.nodeKey === this.selectedKey
  }
}
