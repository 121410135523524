



















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Field, { FieldSizes } from '@/shared/classes/form/field'


@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CreateCompetenceDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null
  loading: boolean = false

  created() {
    this.form = new FormBase()
      .setTranslatable(true)
      .setEntry({ name: this.meta.item })
      .setFields([
        new Field()
          .setKey('name')
          .setSize(FieldSizes.full)
          .isRequired()
          .setTranslatable(true)
          .setTitle(__('company.components.org-structure.competence-dialog.name')),
      ])
      .setOnSuccess(this.meta.onSuccess)
  }

  get isEdit () {
    return Object.keys(this.meta.item || {}).length > 0
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
