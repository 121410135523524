




























































import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import Form from "@/shared/components/form/Form.vue";
import FormBase from "@/shared/classes/form/form-base";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import ArrayField from "@/shared/classes/form/fields/array-field";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import baseHttp from "@/shared/http";
import { FieldTypes } from "@/shared/components/form/field-types";
import _ from "lodash";
import { validateFields } from "@/shared/helpers/validate";
import IUserResponse from "@/shared/modules/user/user-response.interface";
import { setDefaultFieldValue } from "@/shared/classes/form/abstract-form";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import User from "@/shared/modules/user/user.model";
import { getQueryName } from "@/shared/helpers/query.helper";


@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CreateGroupDialog extends Vue {
  @Prop() meta!: any

  http = baseHttp
  form: FormBase | null = null
  loading: boolean = false
  addEmployeesFormVisible = false
  employees: IUserResponse[] = []
  addEmployeesForm: FormBase | null = null;

  created() {
    this.form = new FormBase()
      .setTranslatable(true)
      .setUnsetNonFieldsValues(false)
      .setEntry(this.meta.item)
      .setFields([
        new Field()
          .setKey('title')
          .setTitle(__('company.components.org-structure.group-dialog.title'))
          .setTranslatable(true)
          .setDense(true)
          .setSize(FieldSizes.full)
          .isRequired(),
        new SelectField()
          .setKey("placedUnder")
          .setTitle(__('company.components.org-structure.group-dialog.placed-under'))
          .setDense(true)
          .setItems(this.meta.placedUnderOptions?.map((option: any) => new SelectItem()
            .setValue(option.value)
            .setTitle(option.title)
          )),
        new ArrayField()
          .setKey('persons')
          .setDense(true)
          .setDeleteButtonProps({ color: 'transparent', elevation: 0 })
          .setAddButton(() => false)
          .setInitialLength(0)
          .setChildren([
            new Field()
              .setKey('isManual')
              .setHidden(true),
            new Field()
              .setKey('name')
              .setTitle(__('company.components.org-structure.group-dialog.person'))
              .setSize(FieldSizes.half)
              .setDense(true)
              .setDisabled(true)
              .setVisibleIf((values: any, index: number | null) =>
                !_.get(values, `persons.${ index }.isManual`, false)
              ),
            new Field()
              .setKey('name')
              .setTitle(__('company.components.org-structure.group-dialog.person'))
              .setSize(FieldSizes.half)
              .setDense(true)
              .setClasses('ml-0')
              .setVisibleIf((values: any, index: number | null) =>
                _.get(values, `persons.${ index }.isManual`, false)
              )
              .isRequired(),
            new Field()
              .setKey('competence')
              .setTitle(__('company.components.org-structure.group-dialog.competence'))
              .setSize(FieldSizes.half)
              .setDense(true)
              .setTranslatable(true)
          ])
      ])
      .setValidate(true, { onlyRegionLangRequired: true })
      .setShowDefaultValidationError(true)
      .setOnSuccess(async data => {
        this.meta.onSuccess({
          isGroup: true,
          title: data.title,
          placedUnder: data.placedUnder,
          persons: data.persons
        }, this.meta.nodeKey);

        await this.closeDialog();
      })

    this.http.get(`company/${this.company.slug}/employees?per_page=1000&${getQueryName(FilterOperators.equals, 'status.uuid')}=${User.STATUS_ACTIVE}`)
      .then(resp => this.employees = resp.data.data as IUserResponse[])
  }

  showAddEmployeesForm() {
    const initialSelected: Record<string, boolean> = {};
    this.form?.data.persons.forEach((person: any) => initialSelected[person.personUuid] = true)

    this.addEmployeesForm = new FormBase()
      .setFields(this.employees.map((employee: any) => {
        return new Field()
          .setType(FieldTypes.checkbox)
          .setKey(employee.uuid)
          .setTitle(employee.full_name)
          .setDense(true)
          .setSize(FieldSizes.full)
      }))
      .setInitialValues(initialSelected)
      .setOnSuccess(data => {
        const persons: any = [];
        const selectedUuids = Object.keys(data).filter(key => data[key]);

        this.form?.data.persons.forEach((person: any) => {
          if (person.isManual) return persons.push(person);

          const index = selectedUuids.indexOf(person.personUuid);
          if (index !== -1) {
            persons.push(person);
            selectedUuids.splice(index, 1);
          }
        });

        selectedUuids.forEach(uuid => {
          persons.push({
            isManual: false,
            personUuid: uuid,
            name: this.employees.find((employee: any) => employee.uuid === uuid)?.full_name,
            competence: setDefaultFieldValue(new Field().setKey('competence').setTranslatable(true)),
          })
        })
        _.set(this.form?.data, 'persons', persons)
        this.addEmployeesFormVisible = false;
      })
    this.addEmployeesFormVisible = true;
  }

  addManualPerson() {
    _.set(this.form?.data, 'persons', [
      ..._.get(this.form?.data, 'persons'),
      { isManual: true, name: '', competence: setDefaultFieldValue(new Field().setKey('competence').setTranslatable(true)) }
    ])
  }

  get isEdit () {
    return !!this.meta.nodeKey;
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog);
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
